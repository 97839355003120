import appContext from '../appContext';

export const normalizeSpecialCharacters = function normalizeSpecialCharacters(str) {
    return String(str)
        .replace(/\(TM\)/g, '<sup>&trade;</sup>')
        .replace(/\(R\)/g, '<sup>&reg;</sup>');
};

/**
 * Map the values of a specified property within an array of objects to a new array.
 *      The property string may use dot notation to indicate properties of nested objects.
 *      i.e. pluck(arr, 'propertyName') or pluck(arr, 'propA.propB')
 * @param {Array<Object>} arr An array of objects to search in
 * @param {String} prop The property name to look for
 */
export const pluck = function pluck(arr, prop) {
    const propList = prop.split('.');
    return arr.map((item) => {
        let finalValue = item[propList[0]];
        for (let i = 1; i < propList.length; i++) {
            if (!finalValue[propList[i]]) {
                return null;
            }
            finalValue = finalValue[propList[i]];
        }
        return finalValue;
    });
};

export const scrubFootnotes = function scrubFootnotes(str = '', partTypeName) {
    if (!str) return '';

    // Previous versions of this function replaced these characters every time.
    // Since it's used mainly for fitment descriptions, I assume they must sometimes contain special chars?
    // TODO: This may be something we want to determine and refactor out of this function in the future.
    const newString = normalizeSpecialCharacters(str.replace('&amp;', '&')).trim();

    // Footnotes are encapsulated within square brackets ([])
    if (!newString.includes('[')) {
        return newString;
    }

    const footnoteEnd = newString.indexOf(']') + 1;

    // Don't scrub the footnote if it's "important"
    // A footnote is deemed important if it makes up the entirety of the string AND doesn't repeat the part type name
    if (footnoteEnd === newString.length && typeof partTypeName === 'string' && newString !== `[${partTypeName}]`) {
        return newString;
    }

    // Footnotes are expected to be at the beginning of strings (for product name and fitment description)
    // If that ever changes, we may need to use replace() instead of substring()
    return newString.substring(footnoteEnd).trim();
};

export const isValidAccountNumber = function isValidAccountNumber(accountNumber) {
    if (Number.isInteger(Number(accountNumber))) {
        return accountNumber >= 1 && accountNumber <= 999999999;
    }
    return false;
};

export const getCookie = function getCookie(name) {
    const tempCookie = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return tempCookie ? tempCookie[2] : null;
};

export const setCookie = function setCookie(name, value, days) {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`;
};

export const deleteCookie = function deleteCookie(name) {
    setCookie(name, '', -1);
};

export const getTimeZoneOffsetSeconds = function getTimeZoneOffsetSeconds() {
    return new Date().getTimezoneOffset() * 60;
};

const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
export const formatCurrency = function formatCurrency(value) {
    let cleanValue = Number(value);
    if (Number.isNaN(cleanValue)) {
        cleanValue = 0;
    }
    return currencyFormatter.format(cleanValue);
};

export const formatZipCode = function formatZipCode(zip) {
    if (!zip) return '';

    let zipString = String(zip);

    if (zipString.length > 5 && zipString.indexOf('-') === -1) {
        zipString = `${zipString.slice(0, 5)}-${zipString.slice(5)}`;
    }

    return zipString;
};

export const decodeHtml = function decodeHtml(str) {
    return str ? new DOMParser().parseFromString(str, 'text/html').documentElement.textContent : str;
};

export const expandContent = function expandContent(el) {
    if (!el.classList.contains('transition-all-500ms')) {
        el.classList.add('transition-all-500ms');
    }

    // Cannot use multiple parameters for add/remove on classList in IE11
    el.classList.add('collapsing');
    el.classList.add('show');
    el.style.height = `${el.scrollHeight}px`;

    setTimeout(() => {
        el.classList.remove('collapsing');
        el.style.height = 'auto';
    }, 500);
};

export const collapseContent = function collapseContent(el) {
    if (!el.classList.contains('transition-all-500ms')) {
        el.classList.add('transition-all-500ms');
    }

    el.classList.add('collapsing');
    el.style.height = 0;

    setTimeout(() => {
        // Cannot use multiple parameters for add/remove on classList in IE11
        el.classList.remove('collapsing');
        el.classList.remove('show');
    }, 500);
};

export function stringFormat(str = '', ...args) {
    return str.replace(/\{(\d+)\}/g, ([, key]) => (args[key] !== undefined && args[key] !== null ? args[key] : ''));
}

export const fcoUrl = function relativeUrlWithContext(path = appContext, { params } = {}) {
    // don't try to modify data URLs
    if (path.indexOf('data:') === 0) return path;

    const hasContext = path.slice(0, 4) === 'http' || path.indexOf(appContext) === 0;
    const search = !params ? '' : `${path.includes('?') ? '&' : '?'}${new URLSearchParams(params).toString()}`;
    const url = `${hasContext ? '' : appContext}${!hasContext && path.charAt(0) !== '/' ? `/${path}` : path}`;
    return `${url}${search}`;
};

export const fcoUrlSampleFile = (path, isSpa) => `${isSpa ? '.' : ''}${fcoUrl(path)}`;

/**
 * Round a number to a specified number of decimal places
 * Number.EPSILON is used to handle most cases where floating point precision would be an issue (see: https://floating-point-gui.de/)
 * @param {Number} n the number to round
 * @param {Number} decimalPlaces number of decimal places to round to. if falsey, an integer is returned.
 * @returns Number
 */
export const round = (n, decimalPlaces = 0) => {
    if (!n) return 0;

    // By default (no decimals) we'll return an integer, so no need for funny business.
    if (!decimalPlaces) return Math.round(n);

    // Use exponentiation to determine decimal places. This way we can move the decimal point that many places, round the number, and move it back.
    // i.e. 10 ** 2 = 100 (so move two decimal places when dividing/multiplying)
    const multiplier = 10 ** decimalPlaces;
    return Math.round((n + Number.EPSILON) * multiplier) / multiplier;
};

/**
 * Wait for smooth scrolling to finish before taking an action.
 * @param {Number} top - page y coordinate to scroll to
 * @returns Promise
 */
export const scrollToAsync = (top) =>
    new Promise((resolve) => {
        let lastScrollY = null;

        window.scrollTo({ top, behavior: 'smooth' });

        const checkIsScrolling = setInterval(() => {
            const { scrollY } = window;
            if (lastScrollY === scrollY) {
                clearInterval(checkIsScrolling);
                resolve();
            }
            lastScrollY = scrollY;
        }, 50);
    });

const monthNameFormatter = new Intl.DateTimeFormat(undefined, { month: 'long' });

export const monthNamesLong = [...Array(12)].map((_, i) => {
    const date = new Date();
    date.setDate(1); // avoids problems with duplicate months if current date is 31
    date.setMonth(i);
    return monthNameFormatter.format(date);
});

export const weekdayNamesLong = [...Array(7)].map((_, i) => {
    const d = new Date();
    d.setDate(d.getDate() - d.getDay() + i);
    return d.toLocaleDateString(undefined, { weekday: 'long' });
});

export const getProductSubset = function getProductSubset(product) {
    return {
        partAvailabilityList: product.partPriceAvailabilityResponse.partAvailabilityList,
        price: product.partPriceAvailabilityResponse.price,
        productOrigin: product.productOrigin,
        productOriginDetails: product.productOriginDetails,
        catalogKey: product.catalogKey,
        legacyKey: product.legacyKey,
        itemDescription: product.itemDescription,
        warrantyDescription: product.warranty,
        uomCode: product.unitOfMeasureCode,
        uomDescription: product.unitOfMeasure,
        productCrossSells: product.product.partCrossSells,
        locateItem: product.locateItem,
        showDefaultAvailability: product.showDefaultAvailability,
        oppSourcing: product.oppSourcing,
        kitPartLookupType: product.kitPartLookupType,
    };
};

// function that calculates what position a list item is in a paginated list where starting positions value is 0
export const getPaginatedItemPosition = function getPaginatedItemPosition(listItemIndex, currentPage, maxItemsPerPage) {
    if (currentPage === 1) {
        return listItemIndex;
    }
    return (currentPage - 1) * maxItemsPerPage + listItemIndex;
};

// custom sorting by StringField in Table column
export const sortByStringField = (field, isReverse) => {
    const invert = isReverse ? -1 : 1;
    return (a, b) => {
        let sortValue = 0;
        sortValue = a[field].localeCompare(b[field]);
        return invert * sortValue;
    };
};

/**
 * US Phone Regex:
 *      - Allow leading "+1" or "1" for country code
 *      - Neither area code nor exchange code may start with a zero (0) or one (1)
 *      - Neither area code nor exchange code may end with double ones (11)
 *      - If a parentheses is present before the area code, make sure one exists after the area code as well
 *      - Section delimiters can be any combination of the following: none (no delimiter), hyphen ("-"), or space (" ")
 *      - Must contain ten digits, not including the country code
 */
export const usPhoneRegex =
    /^(?:\+(?=1)){0,1}(?:1[ -]|1){0,1}(?:\((?=\d{3}\))([2-9](?!11)\d{2})\)|([2-9](?!11)\d{2}))[ -]{0,1}([2-9](?!11)\d{2})[ -]{0,1}(\d{4})$/;

export const formatPhoneNumber = function formatPhoneNumber(phoneNumber = '') {
    if (!phoneNumber) return '';
    const [match, area1, area2, exchange, line] = phoneNumber.match(usPhoneRegex) || [];
    return !match ? phoneNumber : `${area1 || area2}-${exchange}-${line}`;
};
